@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap");

.home {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(#f5f5f5, #ffff, #ffff, transparent),
    url("../../assets/pattern-randomized1.svg");
  animation: fadeIn 1.5s ease-in;
  height: calc(100vh - 2.5rem);
}

.home > .container {
  /* padding: 0 30px !important; */
}
.home > .container > h1 {
  margin-top: 200px;
}
.home > .container > div {
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.home > .container > div > h1 {
  font-size: 4rem;
  font-family: "Roboto Slab", Helvetica;
  padding-bottom: 20px 0;
  font-weight: 800;
}
.home > .container > div > h5 {
  font-family: "Quicksand", "Helvetica Neue", Helvetica;
  font-weight: bold;
  padding-bottom: 2rem;
}

.react-rotating-text-cursor {
  padding: 0;
  animation: blinking-cursor 1s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
}

#rotating-text {
  font-family: "Roboto Slab", "Advent Pro", sans-serif;
  color: #7db9b3 !important;
  font-weight: 800;
  font-size: 4rem;
}
@keyframes blinking-cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.home > .container > div > button {
  border: none;
  font-family: "Advent Pro";
  width: fit-content;
  padding: 0.8rem 1.3rem;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin: 0 0 0 12px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.home > .container > div > button:hover {
  cursor: pointer;
}
.home > .container > div > button:hover .wave {
  top: -120px;
}

.home > .container > div > button > span {
  font-size: 1.12rem;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  position: relative;
  z-index: 1;
  color: white;
  animation: slide-fwd-top 0.45s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.home > .container > div > button .wave {
  width: 200px;
  height: 200px;
  background-color: #7db9b3;
  box-shadow: inset 0 0 50px rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 0;
  top: -95px;
  transition: 1s;
}
.home > .container > div > button .wave::before,
.home > .container > div > button .wave::after {
  width: 200%;
  height: 200%;
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-30%, -75%);
}
.home > .container > div > button .wave::before {
  border-radius: 45%;
  background-color: #e2e2e2;
  animation: wave 9s linear infinite;
}
.home > .container > div > button .wave::after {
  border-radius: 40%;
  background-color: rgba(255, 255, 255, 0.74);
  animation: wave 15s linear infinite;
}

@keyframes wave {
  0% {
    transform: translate(-50%, -75%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -75%) rotate(360deg);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slide-fwd-top {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
  }
  100% {
    -webkit-transform: translateZ(160px) translateY(-100px);
    transform: translateZ(160px) translateY(-100px);
  }
}

@media screen and (max-width: 320px) {
}
@media screen and (max-width: 375px) {
}

@media screen and (max-width: 415px) {
}
@media screen and (max-width: 844px), screen and (max-height: 390) {
  .home > .container > div > h1 {
    font-size: 3rem;
  }
  .home > .container {
    margin: 0 20px;
    /* border: 2px black solid; */
    padding: 0 3rem;
  }
  #rotating-text {
    margin-top: 12rem;
  }
  .home > .container > .row > h1 {
    font-size: 3.5 rem;
  }
  .home > .container > .row > h5 {
    font-size: 1.1rem;
  }

  #rotating-text {
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .home {
    /* position: relative; */
    height: calc(100vh);
  }
  .home > .container {
    display: block;
    padding: 0 0 50px 0;
    margin: 25vh 0 0 0;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
