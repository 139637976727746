.contact_me {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(transparent, #fff, #fff, transparent),
    url("../../assets/pattern-randomized1.svg");
  text-align: center;
  animation: fadeIn 1.5s ease-in;
  padding-bottom: 4rem;
}

.contact_me > .container > h1 {
  margin-top: 4rem;
  font-size: 40px;
  font-weight: 600;
  padding: 10px 0 15px 0;
  color: #2d4947;
  font-family: "Roboto Slab", Arial, Helvetica, sans-serif;
}
.contact_me > .container > span:nth-child(2) {
  font-size: 0.9rem;
  color: #009688;
}
.contact_me p {
  margin: 0 auto;
}

.contact_me > .container > .row {
  display: flex;
}
.contact_me > .container > .row > .col {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.contact_me > .container > .row > .col:nth-child(1) > div > a {
  background: #009688;
}
.contact_me > .container > .row > .col:nth-child(2) > div > a {
  background-color: #47b850;
}

.contact_me > .container > .row > .col > div > a {
  font-family: "Advent Pro";
  text-align: center;
  width: 10rem;
  padding: 1rem 1.5rem;
  position: relative;
  display: block;
  text-decoration: none;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border: none;
}

.contact_me > .container > .row > .col > div {
  transition: 0.3s ease-in-out;
}
.contact_me > .container > .row > .col > div:hover {
  cursor: pointer;
  transform: translateY(5px);
  transition: 0.2s ease-in-out;
}

.contact_me > .container > .row > .col > div > a span {
  font-size: 18px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  position: relative;
  z-index: 1;
  color: white;
}

.contact_me > .container > .row > .col > div > a .wave_animation {
  box-shadow: inset 0 0 50px rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 0;
  transition: 0.8s;
}
.contact_me > .container > .row > .col:nth-child(1) > div > a .wave_animation {
  width: 200px;
  height: 204px;
  background-color: #7db9b3;
  top: -95px;
}
.contact_me > .container > .row > .col:nth-child(2) > div > a .wave_animation {
  width: 205px;
  height: 190px;
  background-color: #47b850;
  top: -99px;
}
.contact_me > .container > .row > .col > div > a:hover .wave_animation {
  top: -120px;
}
.contact_me > .container > .row > .col > div > a .wave_animation::before,
.contact_me > .container > .row > .col > div > a .wave_animation::after {
  width: 200%;
  height: 200%;
  content: "";
  position: absolute;
  top: 0;
  left: 45%;
  transform: translate(-30%, -75%);
}
.contact_me > .container > .row > .col > div > a .wave_animation::before {
  border-radius: 45%;
  background-color: #e2e2e2;
  animation: wave 9s linear infinite;
}
.contact_me
  > .container
  > .row
  > .col:nth-child(1)
  > div
  > a
  .wave_animation::after {
  border-radius: 40%;
  background-color: rgba(255, 255, 255, 0.74);
  animation: wave 15s linear infinite;
}
.contact_me
  > .container
  > .row
  > .col:nth-child(2)
  > div
  > a
  .wave_animation::after {
  border-radius: 41%;
  background-color: rgba(255, 255, 255, 0.74);
  animation: wave 13s linear infinite;
}

@keyframes wave {
  0% {
    transform: translate(-50%, -75%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -75%) rotate(360deg);
  }
}
