/* ------------------ STRUCTURE --------------------  
1. Navbar
2. Wave Component
3. Key animations
4. @media formats
 --------------------------------------------------- */

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap");
/* 1. ------------------ NAVBAR --------------------   */
.navbar {
  position: fixed;
  z-index: 9;
  background: #7db9b3;
  animation: slideInDown 1s both;
  animation-name: slideInDown;
  width: 100%;
  transition: top 0.3s;
  display: flex;
  align-items: center;
  z-index: 9;
}
.navbar > div:nth-child(1) {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0 0 20px;
}
.navbar > div:nth-child(1) > img {
  margin: 0;
  padding: 0;
  height: 2.6rem;
  animation: heartbeat 1s infinite alternate;
}
.navbar > div:nth-child(1) > span {
  display: none;
}
.menu-btn-container {
  margin: 0px 20px 0 0px;
}

.dropdown-container.open {
  animation: slide-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  display: flex;
  justify-content: center;
  z-index: 2;
  position: fixed;
  top: 0;
  background: rgb(68, 117, 111);
  height: 100vh;
  width: 100vw;
  z-index: 7;
}

.dropdown-container > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: slide-bottom-text 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.dropdown-container > div > .nav-link {
  color: aliceblue;
  z-index: 5;
  font-size: 3rem;
  margin: 0.7rem;
}
.dropdown-container > div:nth-child(2) {
  position: absolute;
  bottom: 0;
  margin: auto;
}

.nav-link:hover {
  color: rgb(50, 119, 108);
}
.nav-link.active {
  color: rgb(71, 71, 71) !important;
}

@keyframes slide-bottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100vh);
    transform: translateY(-100vh);
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slide-bottom-text {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100vh);
    transform: translateY(-100vh);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
    transform: scale(1) rotateZ(0);
  }
  50% {
    -webkit-transform: scale(1.4) rotateZ(180deg);
    transform: scale(1.4) rotateZ(180deg);
  }
  100% {
    -webkit-transform: scale(1) rotateZ(360deg);
    transform: scale(1) rotateZ(360deg);
  }
}
@keyframes heartbeat {
  to {
    transform: scale(1.1);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}

/*2. ------------------  WAVE COMPONENT --------------------  */

#wave_1,
#wave_2,
#wave_3 {
  /* z-index: -9; */
  position: fixed;
  animation-name: slideInDown;
  /* animation-duration: 1s; */
  animation-fill-mode: both;
  top: 0;
  /* width: 100vw; */
}

#wave_1 {
  animation-duration: 1s;
  transition: top 1s;
  height: 17rem;
}
#wave_2 {
  animation-duration: 1.7s;
  transition: top 1.7s;
  height: 25rem;
}
#wave_3 {
  animation-duration: 2.3s;
  transition: top 1s;
  height: 18rem;
}
.fade-component {
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

/*3. ------------------  @MEDIA --------------------  */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .navbar {
    display: flex;
    align-items: center;
    height: fit-content;
    overflow: hidden;
  }
  .navbar > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .navbar > div:nth-child(1) {
    margin: 0 50px;
    align-self: flex-start;
  }
  .navbar > div:nth-child(1) > img {
    height: 2rem;
    margin: 0 20px;
    margin-left: 160px;
  }
  .navbar > div:nth-child(1) > span {
    display: contents;
    color: #fff;
    font-family: "Roboto", "Arial Narrow", Arial, sans-serif;
    font-weight: 600;
  }
  .navbar > div:nth-child(2) {
    display: flex;
    justify-content: center;
    justify-self: center;
    margin-right: 160px;
  }
  .navbar > div:nth-child(2) > .nav-link {
    color: #fff;
    padding: 0 10px;
    margin: 0 10400;
  }
  .navbar > div:nth-child(2) > .nav-link:hover {
    cursor: pointer;
    color: #cfcfcf;
    transition: 0.3s ease-in-out;
  }
  .navbar > div:nth-child(2) > .nav-link:active {
    color: #346c66;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
