.about_me {
  background:#FFFF;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.about_me > .container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.about_me > .container h1 {
  margin-top: 4rem;
  font-size: 40px;
  font-weight: 600;
  padding: 10px 0 15px 0;
  color: #2d4947;
  font-family: "Roboto Slab", Arial, Helvetica, sans-serif;
}
.about_me > .container > .row > .row:nth-child(1),
.about_me > .container > .row > .row:nth-child(2) {
  padding: 0 !important;
}
.about_me > .container > .row > .row:nth-child(1) > div > div {
  display: flex;
  justify-content: center;
}
.about_me > .container > .row > .row:nth-child(1) > div > div > img {
  transition: opacity 1s ease-in-out;
  border-radius: 5px;
  width: 92%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  filter: grayscale(95%);
}

.about_me > .container > .row > .row:nth-child(2) > div {
  display: flex;
  align-items: center;
  text-align: center;
}

.about_me > .container > .row > .row:nth-child(2) > div > div > .row {
  justify-content: center;
  padding: 1.5rem;
  /* background: #ffffff; */
}
.about_me > .container > .row > .row:nth-child(2) > div > div > .row:hover {
  transition: all 0.5s ease;
}
#about_me
  > div.container
  > div
  > div:nth-child(2)
  > div
  > div
  > div
  > div
  > div.mobile-only {
  display: contents;
}
#about_me
  > div.container
  > div
  > div:nth-child(2)
  > div
  > div
  > div
  > div
  > div:nth-child(1)
  > button {
  color: #2d4947;
  background-color: transparent;
  border: none;
  font-weight: 800;
  filter: drop-shadow(1px 1px 1px #2626268f);
  margin-bottom: 2rem;
}
#about_me
  > div.container
  > div
  > div:nth-child(2)
  > div
  > div
  > div
  > div
  > div.desktop-only {
  display: none;
}
.about_me > .container > .row > .row:nth-child(2) > div > div > .row > button {
  border: none;
  font-family: "Advent Pro";
  width: fit-content;
  padding: 8px;
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.about_me
  > .container
  > .row
  > .row:nth-child(2)
  > div
  > div
  > .row
  button:hover {
  cursor: pointer;
}
.about_me
  > .container
  > .row
  > .row:nth-child(2)
  > div
  > div
  > .row
  button:hover
  .wave {
  top: -120px;
}
.about_me
  > .container
  > .row
  > .row:nth-child(2)
  > div
  > div
  > .row
  button
  span {
  font-size: 18px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  position: relative;
  z-index: 1;
  color: white;
}
.about_me
  > .container
  > .row
  > .row:nth-child(2)
  > div
  > div
  > .row
  > button
  .wave {
  width: 300px;
  height: 300px;
  background-color: #7db9b3;
  box-shadow: inset 0 0 50px rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 0;
  top: -85px;
  transition: 1s;
}
.about_me
  > .container
  > .row
  > .row:nth-child(2)
  > div
  > div
  > .row
  > button
  .wave::before,
.about_me
  > .container
  > .row
  > .row:nth-child(2)
  > div
  > div
  > .row
  > button
  .wave::after {
  width: 200%;
  height: 200%;
  content: "";
  position: absolute;
  top: -66px;
  left: 44%;
  transform: translate(-130%, -175%);
}

.about_me
  > .container
  > .row:nth-child(2)
  > div
  > div
  > .row
  > button
  .wave::before {
  border-radius: 45%;
  background-color: #e2e2e2;
  animation: wave 9s linear infinite;
}
.about_me
  > .container
  > .row
  > .row:nth-child(2)
  > div
  > div
  > .row
  > button
  .wave::after {
  border-radius: 44%;
  background-color: rgba(255, 255, 255, 0.74);
  animation: wave 15s linear infinite;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media screen and (max-width: 320px) {
}
@media screen and (max-width: 375px) {
  .about_me > .container {
    height: 100%;
  }

  .about_me > .container > h1 {
    font-size: 40px;
    font-weight: 600;
    padding: 10px 0 15px 0;
    color: #2d4947;
  }
  .about_me > .container > .row {
    margin: 0;
  }

  .about_me > .container > .row > .row:nth-child(1) > div > div > img {
    width: 100%;
    padding: 0;
  }

  .about_me > .container > .row > .row:nth-child(1) > div {
    /* margin: 0 20px 20px 20px; */
  }

  .about_me > .container > .row > .row:nth-child(2) > div {
    /* margin: 0 20px 10px 0px; */
    text-align: center;
  }
  #about_me > div.container > div > div:nth-child(2) > div > div > div {
    margin: 1rem 0;
    padding: 0.8rem;
  }
  #about_me > div.container > div > div:nth-child(2) > div > div > div p {
    padding: 0;
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .about_me > .container > .row > .row:nth-child(1) > div > div > img.active {
    opacity: 1;
  }
  .about_me > .container > .row > .row:nth-child(1) > div > div > img:hover {
    transform: translateX(-5px);
    transition: all 0.5s ease;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #about_me
    > div.container
    > div
    > div:nth-child(2)
    > div
    > div
    > div
    > div
    > div.desktop-only {
    display: contents;
  }
  #about_me
    > div.container
    > div
    > div:nth-child(2)
    > div
    > div
    > div
    > div
    > div.mobile-only {
    display: none;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #about_me > div.container > div > div:nth-child(1) > div > div > img {
    /* border: red 2px solid; */
    width: 30rem;
    z-index: -1;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
