.side_link_button {
  background: #fff;
  color: black;
  /* color: red; */
  border: none;
  margin-bottom: 30px;
}
.side_link_button:nth-child(1) {
  border-radius: 10px 0 0 10px;
}
.side_link_button:nth-child(3) {
  border-radius: 0;
}
.side_link_button:nth-child(5) {
  border-radius: 0 10px 10px 0;
}
/* Extra small devices (phones, 600px and down) */
@media (max-width: 600px) {
  .side_link_bar {
    z-index: 9;
    position: sticky;
    width: fit-content;
    justify-content: flex-end;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    margin-top: 5px;
    border-radius: 15px 15px 0 0;
  }

  .side_link_bar > .container {
    display: flex;
    flex-direction: row;
    padding: 2px;
    border-radius: 15px 15px 0 0;
  }

  .side_link_button {
    border-radius: 7px 7px 0 0;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .side_link_bar {
    height: fit-content;
    top: calc(50vh - 66px);
    position: fixed;
    padding: 2px 0;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 0px 5px 5px 0px;
    background: #fff;
    z-index: 3;
  }
  .side_link_bar hr {
    margin: 0 5px 0 5px;
    padding: 0;
  }

  .side_link_bar > .container {
    display: flex;
    flex-direction: column;
    padding: 2px;
    border-radius: 15px;
  }
  .side_link_bar > .container > .side_link_button {
    color: black;
    margin: 0;
    height: 50px;
    width: 50px;
    background-color: #fff;
    border: none;
    z-index: 3;
  }
  .side_link_bar > .container > .side_link_button:hover {
    background-color: rgb(243, 242, 242);
    transition: all 0.2s ease-in-out;
  }
  .side_link_bar > .container > .side_link_button i {
    font-size: 25px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
