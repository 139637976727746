/* ------------------ STRUCTURE --------------------  
1. Main Portfolio Section
2. Noteworthy Projects
3. Other Projects
4. Multi Media Screen sizing
5. Animations 
--------------------------------------------------- */

/* 1. MAIN PORTFOLIO SECTION -------------------- */
.portfolio {
  background: linear-gradient(transparent, #f5f5f5, #ffff, #ffff, transparent);
}

.portfolio h1 {
  margin-top: 4rem;
  font-size: 40px;
  font-weight: 600;
  padding: 10px 0 15px 0;
  text-align: center;
  color: #2d4947;
  font-family: "Roboto Slab", Arial, Helvetica, sans-serif;
}

.portfolio hr {
  margin: 5px;
}

.portfolio>.container:nth-child(2) h2,
.portfolio>.container:nth-child(2) h6 {
  text-align: center;
}

.portfolio>.container:nth-child(2)>div {
  justify-content: center;
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

/* 2. NOTEWORTHY PROJECTS SECTION -------------------- */
.noteworthy_project {
  /* padding: 0 1vw; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 3rem;
}

.noteworthy_project img {
  width: 100%;
  object-position: top;
  object-fit: cover;
  height: 12rem;
  filter: grayscale(100%);
  z-index: -1;
  transition: all 0.5s ease;
  border-radius: 0 5px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.noteworthy_project img:hover {
  filter: grayscale(0%);
}

.noteworthy_project .project_heading {
  margin: auto;
  display: flex;
  align-items: center;
}

.noteworthy_project .side_link_button {
  border-radius: 10px;
  width: fit-content;
  font-size: 1rem;
  margin: 5px;
}

.noteworthy_project .side_link_button:hover {
  background: transparent !important;
}

.noteworthy_project>.container>.row {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 10px 50px; */
}

/* Odd Noteworthy Projects */
.noteworthy_project:nth-child(odd) .project_heading {
  text-align: left;
}

.noteworthy_project:nth-child(odd) .side_link_button {
  float: right;
}

.noteworthy_project:nth-child(odd) .project_heading .row {
  float: right;
}

.noteworthy_project:nth-child(odd)>.container>.row {
  text-align: right;
}

.noteworthy_project:nth-child(odd)>.container>.row>div:nth-child(2) {
  /* margin-left: -50px; */

}

/* Even Noteworthy Projects */
.noteworthy_project:nth-child(even) .project_heading {
  text-align: right;
}

.noteworthy_project:nth-child(even) .side_link_button {
  float: left;
}

.noteworthy_project:nth-child(even)>.container>.row {
  flex-direction: row-reverse;
}

.noteworthy_project:nth-child(even)>.container>.row>div:nth-child(2) {
  /* margin-right: -50px; */
}

/* Shared Styles for Even and Odd Projects */
.noteworthy_project>.container>.row:hover {
  transform: translate(0, -5px);
  transition: all 0.5s ease;
}

.noteworthy_project>.container>.row>div:nth-child(2)>div {
  z-index: 2;
  color: #fff;
  background-color: rgb(62, 118, 122);
  border-radius: 5px;
  padding: 12px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.noteworthy_project>.container>.row>div:nth-child(2)>div:hover {
  background-color: rgb(78, 126, 129);
}

/* Flex and margin adjustments for .noteworthy_project */
.noteworthy_project:nth-child(even)>.container>.row>div:nth-child(1)>div>.row>.col {
  justify-content: flex-start;
}

.noteworthy_project:nth-child(odd)>.container>.row>div:nth-child(2)>div>.row>.col-lg-6.col-12:nth-child(1) {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row wrap;
  flex: 4.5;
  padding-right: 0;
}

.noteworthy_project:nth-child(odd)>.container>.row>div:nth-child(2)>div>.row>.col-lg-6.col-12:nth-child(2) {
  justify-content: flex-end;
  padding-left: 0;
  margin: 0;
  flex: 1;
}

.noteworthy_project:nth-child(even)>.container>.row>div:nth-child(2)>div>.row>.col-lg-6.col-12:nth-child(1) {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-flow: row wrap;
  padding-left: 0;
  flex: 4.5;
}

.noteworthy_project:nth-child(even)>.container>.row>div:nth-child(2)>div>.row>.col-lg-6.col-12:nth-child(2) {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row wrap;
  flex: 1;
  padding-right: 0;
}

.noteworthy_project:nth-child(even)>.container>.row>div:nth-child(2)>div>.row {
  flex-direction: row-reverse;
}

.container>.row>div:nth-child(2)>div>.row>.col-lg-6.col-12>.badge {
  background-color: rgb(68, 160, 172) !important;
  margin: 3px;
  cursor: default;
}

.noteworthy_project>.container>.row>.col:nth-child(2)>.row>.col {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 3. OTHER PROJECTS SECTION -------------------- */
.other_projects {
  border: none !important;
  padding: 12px;
  width: 16rem;
  margin: 8px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  transition: all 0.5s ease;
  justify-content: space-evenly;
  align-items: stretch;
}

.other_projects button {
  color: rgb(68, 160, 172);
  background-color: transparent;
  border: none;
}

.other_projects button:hover {
  color: rgb(35, 84, 90) !important;
  background: none !important;
  border: none;
}

.other_projects:hover {
  transform: translate(0, -5px);
  background: #efefef;
}

.other_projects>.card-header>#BsFolder {
  font-size: 3rem;
  color: #009688;
}

.other_projects>.card-header {
  display: flex;
  align-items: flex-start;
  padding: 0;
  background-color: transparent;
  border: none;
}

.other_projects>.card-header>.col {
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

.other_projects>.card-header>.col button {
  justify-items: flex-end;
  padding: 8px;
}

.other_projects>.card-body {
  padding: 5px 0;
}

.other_projects>.card-footer {
  display: flex;
  padding: 0 2px;
  background-color: transparent;
  border: none;
  flex-wrap: wrap;
}

.other_projects>.card-footer>.badge {
  margin: 2px;
  background-color: #7db9b3 !important;
}

/* 4. MEDIA QUERIES -------------------- */
@media all and (max-width: 375px) and (orientation: portrait) {
  .noteworthy_project {
    min-width: 300px;
  }

  .noteworthy_project>.container>.row>.col-md-6.col-md-12:nth-child(2) {
    padding: 0 15px;
    margin-top: -4rem;
    margin-bottom: 3rem;
    min-width: 250px;
  }

  .noteworthy_project>.container {
    justify-content: center;
    width: 80vw;
    padding: 0;
    margin: 0;
  }

  .noteworthy_project:nth-child(even)>.container>.row>div:nth-child(2)>div>.row {
    flex-direction: row;
  }

  .noteworthy_project:nth-child(even)>.container>.row>div:nth-child(2)>div>.row>.col-lg-6.col-12:nth-child(2) {
    flex: 1;
  }
}

@media all and (max-width: 475px) and (orientation: portrait) {

  .noteworthy_project:nth-child(odd)>.container>.row>div:nth-child(2),
  .noteworthy_project:nth-child(even)>.container>.row>div:nth-child(2) {
    margin: 0;
  }

  .noteworthy_project>.container>.row>div:nth-child(2)>div {
    text-align: center;
  }

  .other_projects {
    width: 12rem;
  }
}

@media all and (max-width: 768px) {
  .noteworthy_project img {
    width: 100%;

  }

  .noteworthy_project:nth-child(odd)>.container>.row>div:nth-child(2),
  .noteworthy_project:nth-child(even)>.container>.row>div:nth-child(2) {
    margin: 0;
  }

  .noteworthy_project>.container>.row>div:nth-child(2)>div {
    text-align: center;
  }

  .noteworthy_project>.container>.row>div:nth-child(2)>div>.row>.col-lg-6.col-md-12 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 5px;
  }

  .other_projects {
    width: 14rem;
  }
}

@media all and (min-width: 768px) {
  .noteworthy_project {
    margin: 40px 0;
  }

  .noteworthy_project img {
    height: 20rem;
    object-position: top;
  }

  .noteworthy_project>.container>.row>div:nth-child(2) {
    /* padding: 15px 0; */
    margin: 0;
  }
}

@media all and (min-width: 992px) {
  .noteworthy_project img {
    width: 100%;
  }

  .noteworthy_project>.container>.row {

    padding: 10px 50px;
  }

  .noteworthy_project:nth-child(odd)>.container>.row>div:nth-child(2) {
    margin-left: -50px;
  }

  .noteworthy_project:nth-child(even)>.container>.row>div:nth-child(2) {
    margin-right: -50px;
  }
}

/* 5. ANIMATIONS -------------------- */
@keyframes zoomIn {
  0% {
    background-position: center top;
    background-size: 100%;
  }

  100% {
    background-position: center top;
    background-size: 150%;
  }
}

@keyframes myAnim {
  0% {
    greyscale-opacity: 100;
  }

  100% {
    greyscale-opacity: 0;
  }
}