.toolbox {
  background: linear-gradient(transparent, #ffff, #fff, #fff, transparent),
    url("../../assets/pattern-randomized1.svg");
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.toolbox > .container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.toolbox > .container h1 {
  margin-top: 4rem;
  font-size: 40px;
  font-weight: 600;
  padding: 10px 0 15px 0;
  color: #2d4947;
  font-family: "Roboto Slab", Arial, Helvetica, sans-serif;
}
.toolbox > .container > .row {
  display: flex;
  text-align: center;
  justify-content: center;
}
.toolbox > .container > .row > div:nth-child(1) {
  border-radius: 5px;
  /* padding: 1.5rem; */
}

.toolbox > .container > .row > .row {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 20px 20px 0px 20px;
}

.toolbox > .container > .row > div:nth-child(2) > div > div:nth-child(1) {
  font-size: 3rem;
  filter: drop-shadow(2px 1px 2px #315e57ba);
  transition: all 0.5s ease-in-out;
}
.toolbox > .container > .row > div:nth-child(2) > div > div:nth-child(1):hover {
  transform: scale(1.3);
  transition: all 0.5s ease-in-out;
}
.column {
  display: flex;
  align-items: center;
  justify-content: baseline;
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 0.8s ease-out, transform 0.1s ease-out;
  flex-direction: column;
}

.column > .row:nth-child(2) {
  padding: 1rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

.column.visible {
  opacity: 1;
  transform: translateX(0);
}
.column.fade-out {
  animation-name: slide-in;
  animation-duration: 0.3s;
}

.fade-out {
  animation-name: fade-out;
  animation-duration: 0.01s;
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateX(0%);
  }
  to {
    opacity: 1;
    transform: translateX(100%);
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
    transform: translateX(0%);
  }
  to {
    opacity: 0;
    transform: translateX(50%);
  }
}

@media screen and (max-width: 320px) {
}
@media screen and (max-width: 375px) {
  .toolbox > .container {
    /* height: 100%; */
    position: relative;
  }

  .toolbox > .container h1 {
    font-size: 40px;
    font-weight: 600;
    padding: 10px 0 15px 0;
    color: #2d4947;
  }
  .toolbox > .container > .row {
    /* background: #fff; */
  }
}
@media screen and (max-width: 415px) {
  .toolbox > .container {
    /* height: 100%; */
    position: relative;
  }
  .toolbox > .container h1 {
    margin-top: 4rem;
    font-size: 40px;
    font-weight: 600;
    padding: 10px 0 15px 0;
    color: #2d4947;
    font-family: "Roboto Slab", Arial, Helvetica, sans-serif;
  }
}

@media screen and (max-width: 844px), screen and (max-height: 390) {
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
