@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Quicksand&family=Roboto&display=swap");

html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  overflow-x: hidden;
  background: #fff;
  max-width: 100%;
}
body::-webkit-scrollbar {
  width: 0.9rem;
  display: none;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.425);
}

body::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background-color: #5f8f88;
  outline: 1px solid rgb(112, 131, 144);
}

.App {
  width: auto;
  height: 100%;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  z-index: 2;
}

/* ---------------- Scroll Jump -------------- */

.arrow-container {
  display: flex;
  width: 100px;
  height: 100px;
  position: relative;
  display: block;
  z-index: 2;
}
.arrow-container:hover {
  cursor: pointer;
}
.arrow-container:hover .arrow {
  top: 50%;
}
.arrow-container:hover .arrow:before {
  transform: translate(-50%, -50%) rotateZ(-30deg);
}
.arrow-container:hover .arrow:after {
  transform: translate(-50%, -50%) rotateZ(30deg);
}
.arrow-container.show {
  opacity: 1;
}
.arrow-container.hidden {
  animation-name: slide-in;
  animation-duration: 2s;
}

.hidden {
  animation-name: fade-out;
  animation-duration: 1s;
}

.arrow {
  position: absolute;
  left: 50%;
  transition: all 0.4s ease;
}
.arrow:before,
.arrow:after {
  display: flex;
  transition: all 0.4s ease;
  content: "";
  position: absolute;
  justify-content: center;
  transform-origin: bottom right;
  background: rgb(22, 103, 105);
  width: 5px;
  height: 30px;

  border-radius: 10px;
  transform: rotateZ(-45deg);
}
.arrow:after {
  transform-origin: bottom left;
  transform: rotateZ(45deg);
}
.arrow:nth-child(1) {
  opacity: 0.3;
  top: 35%;
}
.arrow:nth-child(2) {
  opacity: 0.6;
  top: 55%;
}
.arrow:nth-child(3) {
  opacity: 0.9;
  top: 75%;
}

/* FOOTER ------------------------------------------ */

.footer {
  position: relative;
  width: 100%;
  background: #2c6f65;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  padding: 0.5rem;
}
.footer a:active {
  color: #2b4642;
}
.footer a:hover {
  color: #2b4642;
}

.menu {
  position: relative;
  display: flex;
  justify-self: baseline;
  align-self: center;
  flex-wrap: wrap;
  padding: 0;
}

.menu__item {
  list-style: none;
}

.menu__link {
  font-size: 1.1rem;
  color: #fff;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
  text-decoration: none;
  opacity: 0.75;
  font-weight: 300;
}

.menu__link:hover {
  opacity: 1;
}

.footer p {
  color: #fff;
  margin: 0;
  font-size: 1rem;
  font-weight: 300;
}

@keyframes animateWaves {
  0% {
    background-position-x: 1000px;
  }
  100% {
    background-positon-x: 0px;
  }
}

@keyframes animate {
  0% {
    background-position-x: -1000px;
  }
  100% {
    background-positon-x: 0px;
  }
}

@keyframes pulse {
  0% {
    opacity: 0.8;
    transform: scale(0.2);
  }

  80% {
    opacity: 0;
    transform: scale(1.2);
  }

  100% {
    opacity: 0;
    transform: scale(2.2);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slideInDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
  }
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateX(0%);
  }
  to {
    opacity: 1;
    transform: translateX(100%);
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
    transform: translateX(0%);
  }
  to {
    opacity: 0;
    transform: translateX(50%);
  }
}
@media only screen and (max-width: 390px) {
  .arrow-container {
    margin-top: -1rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .arrow-container {
    /* margin-top: 3rem; */
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .arrow-container {
    margin-top: 5rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  /* Scroll Top Jump -----------------------------*/

  .scroll_top_container {
    z-index: 4;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
      rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
      rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
    width: 100px;
    height: 100px;
    position: fixed;
    bottom: 0.5rem;
    right: 0.5rem;
    box-shadow: none;
    border: none;
    animation: fadeIn 1s linear ease-in;
  }
  .scroll_top_container.visible {
    animation: fadeIn forwards 2s;
  }

  .scroll_top_container.hidden {
    animation: fadeOut backwards 2s;
    visibility: hidden;
  }

  .scroll_top_container:hover {
    cursor: pointer;
  }

  #scroll_top_btn {
    height: 70px;
    width: 70px;
    border-radius: 100%;
    background: linear-gradient(rgba(85, 148, 154, 0.931) 0%, #5f8f88 100%);
    border: none;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  }
  #scroll_top_btn:hover {
    background-color: #446762;
    -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
      both;
    animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }

  #scroll_top_btn {
    font-size: 2rem;
  }

  /* PRELOADER */

  .preloader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    -webkit-animation: fadeinout 3s linear forwards;
    animation: fadeinout 3s linear forwards;
    background: rgb(0, 33, 36);
    background: linear-gradient(
      180deg,
      rgba(0, 33, 36, 1) 0%,
      rgba(69, 120, 125, 1) 100%
    );
  }

  .preloader-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 60px;
    opacity: 0.7;
    border-radius: 30px;
    animation: pulse 1s ease-in-out 0s infinite alternate both;
  }
}
